.viewer-loading-overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: #1c1c1c;
  color: $color-info-light;
  padding-top: 10%;
}

.viewer-status-spinner {
  .default-spinner-container {
    span {
      color: $color-info-light;
    }
    .spinner-container-text {
      font-size: small;
    }
  }
}

.image-bar-item {
  height: 100px;
  width: 100px;
}

.viewer-overlay-details-container {
  position: absolute;
  color: $color-info-light;
  &.overlay-left {
    left: 9.5rem;
  }
  &.overlay-right {
    right: 9.5rem;
  }
  &.overlay-top {
    top: 2%;
  }
  &.overlay-bottom {
    bottom: 10%;
  }
}

.tool-spinner-container {
  position: relative;
  text-align: center;
  span: {
    width: 100%;
    margin-top: 0.5rem;
  }
}

.download-spinner-container {
  display: flex;
  .default-spinner-container {
    flex-direction: row;
    .spinner-container-text {
      font-size: small;
      margin-top: 0;
      margin-left: 10px;
    }
    span {
      color: white;
    }
  }
}

.dicom-viewport-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 48px);
  width: 100vw;

  .dicom-scrub-bar-container {
    width: 10px;
    height: calc(100vh - 48px);
    background-color: #000000;
    position: relative;
    margin-left: 8.5rem;
    margin-top: 48px;
  }
  .scrub-thumb {
    width: 100%;
    height: 30px;
    background-color: #fff;
    position: absolute;
    border-radius: 5px;
    cursor: pointer;
  }
}
