.dropdown-container {
  position: relative;
}
.table-dropdown {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  text-overflow: ellipsis;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  .dropdown-placeholder {
    color: "grey";
  }
}
.expand-more-icon {
  position: absolute;
  top: 20%;
  right: 5%;
  pointer-events: none;
}
