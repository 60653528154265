.xcaliber-loader {
  height: 100vh;
  width: 100vw;
  .default-spinner-container {
    padding-top: 20%;
    span {
      color: $user-maroon;
    }
  }
}

.xcaliber-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 20px;
  position: relative;
  margin: 1rem 0.1rem;
  .header-container {
    width: 20%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    background-color: $grey-color;
    padding: 0.1rem 1rem 0.5rem 1rem;
    .title {
      font-size: 18px;
      font-weight: $medium;
    }
  }
  .patient-details-container {
  }
  .xcaliber-model-selection {
    .info-text {
      color: $color-info;
    }
    .model-selection-input {
      display: flex;
      justify-content: space-between;

      .action-button {
        border: 0px;
        background-color: $user-maroon;
      }
    }
  }

  .default-spinner-container {
    span {
      color: $user-maroon;
    }
  }
}
