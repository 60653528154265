// DEPENDENCY STYLES
@import "react-toastify/dist/ReactToastify.css";
@import "bootstrap/css/bootstrap.min.css";

// VARIABLES AND THEMES
@import "variables.scss";
// TOP LEVEL STYLES
@import "app.scss";

// PAGE STYLES
@import "pages/viewer/viewer.scss";
@import "pages/login/login.scss";
@import "pages/xcaliber/xcaliber.scss";

// COMPONENT STYLES
@import "components/table/viewer-link/viewerLink.scss";
@import "components/spinner/DefaultSpinner.scss";
@import "components/header/appHeader.scss";
@import "components/buttons/Buttons.scss";
@import "components/darkmode-switch/darkmode-switch.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.user-color-gray {
  color: $user-gray;
}

.user-color-aqua {
  color: $user-aqua;
}
.user-color-green {
  color: $user-green;
}

.user-color-maroon {
  color: $user-maroon;
}
