// Colors
$primary-text: #1c1c1c;
$color-info: #3495eb;
$color-info-light: #65aae7;
$grey-color: rgb(229, 229, 229);
//weight
$bold: 700;
$medium: 500;

$user-gray: #9f9f9f;
$user-aqua: #24bfd0;
$user-green: #15aa4e;
$user-maroon: #7f1c53;
