.action-button {
  border-radius: 4px;
  background-color: $user-gray;
  padding: 8px 16px;
  font-size: 0.875rem;
  color: white;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  font-weight: $medium;

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(
        255,
        255,
        255,
        0.2
      ); // Adjust transparency as needed
      border-radius: 4px; // Match the parent element's border radius
      z-index: 1;
    }
    span : {
      color: white;
    }
  }
  position: relative;
  z-index: 0; // Set the stacking context for the overlay effect

  // Disabled state styles
  &:disabled {
    background-color: grey;
    color: lightgray;
    cursor: not-allowed; // Disables pointer interactions
    box-shadow: none; // Remove hover box shadow
    opacity: 0.6; // Reduce opacity for a "disabled" appearance

    &:hover {
      box-shadow: none; // Disable hover effect
      &::after {
        display: none; // No overlay effect when disabled
      }
    }
  }
}
