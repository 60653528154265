.app-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0 1rem 1rem;
  .home-icon-image {
    margin: -15px 0px;
    height: 3.5rem;
  }
  .header-subtitle {
    float: right;
    font-weight: $medium;
    margin-top: -15px;
  }
  .app-controls {
    display: flex;
    column-gap: 20px;
  }
}
