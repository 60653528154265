.inline-image-link {
  position: relative;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: $primary-text;
  align-items: center;
  img {
    width: 120px;
    height: 120px;
  }

  .image-link-footer {
    display: flex;
    font-weight: $bold;
  }
  :hover {
    text-decoration: underline;
    color: $primary-text;
  }
}
