body {
  width: 100%;
  height: 100%;
  overflow: auto;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

body::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.App {
  text-align: center;
}

.row-animation {
  animation: movetoRight 0.65s ease-in;
}
@keyframes movetoRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
    transition-delay: 10ms;
  }
}
.paginate-button {
  background: none;
  text-align: center;
  padding: 5px 10px;
  color: black;
  border-radius: 24px;
  transition: 0.25s;
  cursor: pointer;
}

.paginate-button:hover {
  color: white;
}
