.login-form {
  max-width: 350px;
  margin: 0 auto;
  border: 1px solid #afaeae;
  position: fixed;
  top: 20%;
  left: calc(50% - 175px);
}

.login-spinner-container {
  height: 100vh;
  width: 100vw;
  .default-spinner-container {
    padding-top: 20%;
    span {
      color: $user-maroon;
    }
  }
}
