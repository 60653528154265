.default-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .spinner-container-text {
    margin-top: 1rem;
    font-size: medium;
    font-weight: $bold;
  }
  span {
    color: $color-info;
  }
}
